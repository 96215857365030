// Here you can add other styles
.error,
.red {
  color: #f86c6b;
}
.edit-icon {
  color: #20a8d8;
  // font-size: 20px;
  cursor: pointer;
}
.custom-table {
  tr {
    &:first-child {
      td,
      th {
        border-top: 0px;
      }
    }
  }
}

.form-control {
  margin-bottom: 10px;
}
.bg-inherit {
  background: inherit;
}

.history-tl-container {
  position: relative;
}
.history-tl-container ul.tl {
  margin: 20px 0;
  padding: 0;
}
.history-tl-container ul.tl li {
  list-style: none;
  margin: auto;
  margin-left: 80px;
  min-height: 50px;
  border-left: 1px dashed #a5db5f;
  padding: 0 0 10px 30px;
  position: relative;
}
.history-tl-container ul.tl li:last-child {
  border-left: 0;
}
.history-tl-container ul.tl li::before {
  position: absolute;
  left: -18px;
  top: -5px;
  content: " ";
  border: 8px solid rgba(255, 255, 255, 0.74);
  border-radius: 500%;
  background: #a5db5f;
  height: 35px;
  width: 35px;
  transition: all 500ms ease-in-out;
}
ul.tl li .timestamp {
  color: #8d8d8d;
  position: absolute;
  width: 100px;
  left: -100px;
  text-align: right;
  // font-size: 15px;
  padding-right: 30px;
  font-weight: bold;
  text-align: right;
  line-height: 1.2;
  top: -5px;
}
.tl-item {
  .card-sub {
    margin-top: 1.5rem;
    margin-bottom: 0;
    height: calc(100% - 1.5rem);
  }
  .card-main {
    // margin-top: 1.5rem !important;
    margin-bottom: 2rem !important;
  }
  .creator-label {
    position: absolute;
    padding: 3px 10px;
    background: #fff;
    top: -15px;
    // font-size: 15px;
    font-weight: bold;
  }
}
.elipses {
  font-weight: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 160px;
  text-transform: initial;
}
.no-shadow {
  box-shadow: none !important;
}
.md-stepper-horizontal-content {
  padding: 15px;
}
.md-stepper-horizontal {
  display: table;
  width: 100%;
  margin: 0 auto;
  background-color: #ffffff;
  box-shadow: 0 0px 2px -1px rgba(0, 0, 0, 0.5);
}
.md-stepper-horizontal .md-step {
  display: table-cell;
  position: relative;
  padding: 15px;
  width: 33.33%;
}
.md-stepper-horizontal .md-step:hover,
.md-stepper-horizontal .md-step:active {
  background-color: rgba(0, 0, 0, 0.04);
}
// .md-stepper-horizontal .md-step:active {
// 	border-radius: 15% / 75%;
// }
// .md-stepper-horizontal .md-step:first-child:active {
// 	border-top-left-radius: 0;
// 	border-bottom-left-radius: 0;
// }
// .md-stepper-horizontal .md-step:last-child:active {
// 	border-top-right-radius: 0;
// 	border-bottom-right-radius: 0;
// }
.md-stepper-horizontal .md-step:hover .md-step-circle {
  background-color: #757575;
}
.md-stepper-horizontal .md-step:first-child .md-step-bar-left,
.md-stepper-horizontal .md-step:last-child .md-step-bar-right {
  display: none;
}
.md-stepper-horizontal .md-step .md-step-circle {
  width: 30px;
  height: 30px;
  margin: 0 auto;
  background-color: #999999;
  border-radius: 50%;
  text-align: center;
  line-height: 30px;
  // font-size: 16px;
  font-weight: 600;
  color: #ffffff;
}
.md-stepper-horizontal.orange .md-step.active {
  background-color: rgba(0, 0, 0, 0.04);
}
.md-stepper-horizontal.green .md-step.active .md-step-circle {
  background-color: #a5db5e;
}
.md-stepper-horizontal.orange .md-step.active .md-step-circle {
  background-color: #a5db5e;
}
.md-stepper-horizontal .md-step.active .md-step-circle {
  background-color: rgb(33, 150, 243);
}
.md-stepper-horizontal .md-step.done .md-step-circle:before {
  font-family: "Montserrat";
  font-weight: 100;
  content: "\f00c";
}
.md-stepper-horizontal .md-step.done .md-step-circle *,
.md-stepper-horizontal .md-step.editable .md-step-circle * {
  display: none;
}
.md-stepper-horizontal .md-step.editable .md-step-circle {
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.md-stepper-horizontal .md-step.editable .md-step-circle:before {
  font-family: "Montserrat";
  font-weight: 100;
  content: "\f040";
}
.md-stepper-horizontal .md-step .md-step-title {
  margin-top: 16px;
  // font-size: 16px;
  font-weight: 600;
}
.md-stepper-horizontal .md-step .md-step-title,
.md-stepper-horizontal .md-step .md-step-optional {
  text-align: center;
  color: rgba(0, 0, 0, 0.26);
}
.md-stepper-horizontal .md-step.active .md-step-title {
  font-weight: 600;
  color: rgba(0, 0, 0, 0.87);
}
.md-stepper-horizontal .md-step.active.done .md-step-title,
.md-stepper-horizontal .md-step.active.editable .md-step-title {
  font-weight: 600;
}
// .md-stepper-horizontal .md-step .md-step-optional {
//   font-size: 12px;
// }
.md-stepper-horizontal .md-step.active .md-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.md-stepper-horizontal .md-step .md-step-bar-left,
.md-stepper-horizontal .md-step .md-step-bar-right {
  position: absolute;
  top: 30px;
  height: 1px;
  border-top: 1px solid #dddddd;
}
.md-stepper-horizontal .md-step .md-step-bar-right {
  right: 0;
  left: 50%;
  margin-left: 20px;
}
.md-stepper-horizontal .md-step .md-step-bar-left {
  left: 0;
  right: 50%;
  margin-right: 20px;
}
.md-step-content {
  display: none;
  &.active {
    display: block;
  }
}
.creators-table {
  height: 300px;
  overflow-y: auto;
}
.creators-table::-webkit-scrollbar {
  width: 6px;
}
.creators-table::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.creators-table::-webkit-scrollbar-thumb {
  background: #888;
}
.creators-table::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.custom-loader {
  text-align: center;
  width: 100%;
  height: 100%;
  position: absolute;
  vertical-align: middle;
  z-index: 99;
  i {
    position: relative;
    top: 50%;
  }
}

.app-header .navbar-brand {
  left: 0px;
  margin-left: 0;
  width: 0px;
}

// .tooltip-wrapper{
//     .tooltip {
//         background: #fdfdfd;
//         bottom: 100%;
//         color: #333;
//         margin-bottom: 4px;
//         opacity: 0;
//         pointer-events: none;
//         position: absolute;
//         -webkit-transition: all .25s ease-out;
//         -moz-transition: all .25s ease-out;
//         -ms-transition: all .25s ease-out;
//         -o-transition: all .25s ease-out;
//         transition: all .25s ease-out;
//         -webkit-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
//         -moz-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
//         -ms-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
//         -o-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
//         box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
//         min-width: 200px;
//         right: 0;
//         left: 50%;
//         transform: translate(-50%, 0);
//         border: 1px solid rgba(0,0,0,.2);
//         border-radius: 5px;
//         font-size: 16px;
//         width: 90%;
//         // &:before {
//         //     bottom: -20px;
//         //     content: " ";
//         //     display: block;
//         //     height: 20px;
//         //     left: 0;
//         //     position: absolute;
//         //     width: 100%;
//         // }
//         // &:after {
//         //     border-left: solid transparent 10px;
//         //     border-right: solid transparent 10px;
//         //     border-top: solid #1496bb 10px;
//         //     bottom: -10px;
//         //     content: " ";
//         //     height: 0;
//         //     left: 50%;
//         //     margin-left: -13px;
//         //     position: absolute;
//         //     width: 0;
//         // }
//         .icon{
//             position: absolute;
//             margin: auto;
//             bottom: -6px;
//             left: 0;
//             right: 0;
//             width: 10px;
//             height: 10px;
//             transform: rotate(45deg);
//             -webkit-transform: rotate(45deg);
//             -moz-transform: rotate(45deg);
//             -o-transform: rotate(45deg);
//             -ms-transform: rotate(45deg);
//             border-right: 1px solid rgba(0, 0, 0, 0.2);
//             border-bottom: 1px solid rgba(0, 0, 0, 0.2);
//             background: #fff;
//         }
//     }
//     &:hover {
//         .tooltip {
//             opacity: 1;
//             pointer-events: auto;
//         }
//     }
// }
