// Styles
// CoreUI Icons Set
// @import "~@coreui/icons/css/coreui-icons.css";
// Import Flag Icons Set
// @import '~flag-icon-css/css/flag-icon.min.css';
// Import Font Awesome Icons Set
// @import "~font-awesome/css/font-awesome.min.css";
// Import Simple Line Icons Set
@import "~simple-line-icons/css/simple-line-icons.css";
// Import Main styles for this application
@import "./scss/style.scss";

.work-in-progress {
  user-select: none;
  opacity: 0.35;
}
@media (max-width: 991.98px) {
  .app-header .navbar-brand {
    left: 45px;
    margin-left: 0;
    width: auto;
  }
}
.table-responsive {
  max-height: 1000px;
}
.table-header-fixed {
  thead {
    position: sticky !important;
    top: 0 !important;
    z-index: 9;
    background-color: #fff;
    color: #000;
    th {
      background-color: #fff;
      color: #000;
      position: sticky !important;
      top: 0 !important;
      z-index: 9;
    }
  }
}
.c-main {
  flex-basis: auto;
  flex-shrink: 0;
  flex-grow: 1;
  min-width: 0;
  padding-top: 2rem;
  background: #ebedef;
  font-family: "Montserrat";
  font-size: 0.8675rem;
}
.mainContainer {
  margin: 26px 0;
  padding: 16px 16px 0 16px;
  background: white;
  min-height: 80vh;
  border: 1px solid #cccccc;
  // height: 735px;
  margin-top: 0px;
}

.loginButton {
  background-color: #4285f4 !important;
  border: none !important;
  color: #fff !important;
}
.loginButton > div {
  padding: 13px !important;
}
.loginButton > span {
  font-size: 16px;
  padding: 10px 20px !important;
}
.logoutBtn {
  width: 100%;
}
.logoutBtn > div {
  display: none;
}
.logoutBtn > span {
  padding: 10px 20px !important;
}
.disable {
  opacity: 0.4;
  cursor: not-allowed;
  pointer-events: none;
}
.error {
  font-size: 12px;
  line-height: 1.2;
  color: #f34140;
}
.pagination .page-display {
  cursor: auto;
}

.sidebar .active {
  background: #ec0b1e;
}
.user-logo {
  color: black;
  border-radius: 100%;
  border: 1px solid black;
  padding: 5px;
}
.sidebar .nav-item:hover .nav-link:hover {
  background: transparent;
  border-left-width: 4px;
  border-left-style: solid;
  border-left-color: #d88220;
}
.profile-dropdown-sec {
  .profile-list {
    list-style-type: none;
    .c-avatar {
      max-width: 40px !important;
    }
  }
  .dropdown-header {
    font-size: 14px;
    text-align: left !important;
    color: #444;
  }
}
.App-logo {
  width: 35px;
  font-size: 0em;
  // color: white;
}

.error {
  font-size: 10px;
  line-height: 1.2;
  color: #f34140;
  // white-space: nowrap;
}
.c-sidebar-nav {
  position: relative;
  display: flex;
  flex: 1 1;
  flex-direction: column;
  padding: 0;
  margin-bottom: 0;
  overflow-x: hidden;
  overflow-y: auto;
  list-style: none;
  font-family: Montserrat;
  // font-size: 0.8675rem;
  font-size: 16px;
}
.c-sidebar-nav-ico {
  flex: 0 0 56px;
  height: 22px;
  text-align: center;
  transition: 0.3s;
  fill: currentColor;
}
.c-sidebar .c-sidebar-nav-link:hover,
.c-sidebar .c-sidebar-nav-dropdown-toggle:hover {
  color: #fff;
  background: scroll;
}
.c-sidebar .c-sidebar-nav-link.c-active,
.c-sidebar .c-active.c-sidebar-nav-dropdown-toggle {
  color: #fff;
  background: #0056b3;
}
.c-sidebar-minimized .c-sidebar-nav-link.c-active,
.c-sidebar-minimized .c-active.c-sidebar-nav-dropdown-toggle {
  color: #fff;
  background: #0056b3;
}
.c-sidebar-minimized .c-sidebar-nav-link:hover,
.c-sidebar-minimized .c-sidebar-nav-dropdown-toggle:hover {
  width: 55px;
  color: #fff !important;
  background: scroll !important;
}
.btn-primary {
  color: #fff;
  background-color: #3ac0ff;
  border-color: #3ac0ff;
}
.tid-logo {
  max-width: 100%;
  max-height: 30px;
}

@media (max-width: 1400px) {
  .table-responsive {
    max-height: 600px;
  }
}
@media (max-width: 1024px) {
  .table-responsive {
    max-height: 600px;
  }
}
@media (max-width: 480px) {
  .table-responsive {
    max-height: 400px;
  }
}
